import Banner from "../../app/Banner";

import ServicesSection from "../../app/ServicesSection";
import BookSection from "../../app/BookSection";
import TestimonialSection from "../../app/TestimonialSection";

function Services() {


    return (
<>
        <Banner title="Services" backgroundImage="img/lodge.jpg"/>

        <ServicesSection/>
        <BookSection/>
        <TestimonialSection/>
        
    
    
        </>

        );
    }
    export default Services;