import Banner from "../app/Banner";
import AboutSection from "../app/AboutSection";
import FactsSection from "../app/FactsSection";
import TeamSection from "../app/TeamSection";

function About() {


    return (
<>
        <Banner title="About Us" backgroundImage="img/car6.jpg"/>

        <AboutSection/>
        <FactsSection/>
    
    
        <TeamSection/>
        </>

        );
    }
    export default About;