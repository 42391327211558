import React from 'react';
import { useQuery, gql } from '@apollo/client';


const GET_TEAM_INFO = gql`
query WebsiteInfos {
  teams {
    id
    fullName
    facebookLink
    linkedInLink
    position
    twitterLink
    image {
      url
    }
  }
}


`;
function TeamSection() {

    const { loading, error, data } = useQuery(GET_TEAM_INFO);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { teams } = data;
    return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-primary text-uppercase"> Our Team Members </h6>
                <h1 className="mb-5">Our Experts</h1>
            </div>
            <div className="row g-4">
            {teams.map((member) => (
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item">
                        <div className="position-relative overflow-hidden">
                            <img className="img-fluid" src={member.image.url} alt=""/>
                            <div className="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                <a className="btn btn-square mx-1" href={member.facebookLink} target='_blank' rel='noreferrer'><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square mx-1" href={member.twitterLink} target='_blank' rel='noreferrer'><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square mx-1" href={member.linkedInLink} target='_blank' rel='noreferrer'><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="fw-bold mb-0">{member.fullName}</h5>
                            <small>{member.position}</small>
                        </div>
                    </div>
                </div>
                ))}
               
              
              
            </div>
        </div>
    </div>


  );
}

export default TeamSection;
