import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


function BookSection() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_5uaweuj', 'template_tp9v8ib', form.current, {
            publicKey: 'hbk2EPLpCZU_r9Jbn',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              alert("Thank you for your email. We will get back to you shortly.");
              form.current.reset();
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };
    
  return (
    <div className="container-fluid bg-secondary booking my-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row gx-5">
            <div className="col-lg-6 py-5">
                <div className="py-5">
                    <h1 className="text-white mb-4"> We provide reliable and excellent services</h1>
                    <p className="text-white mb-0"> in car hire, logding, and education in Malawi and beyond. We are operating in Blantyre with strong networks with various companies across all regions in Malawi and beyond</p>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="bg-primary h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
                    <h1 className="text-white mb-4">Inquire about a Service</h1>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="row g-3">
                            <div className="col-12 col-sm-6">
                                <input type="text" className="form-control border-0" placeholder="Your Name" style={{ height: '55px' }} name="name"/>
                            </div>
                            <div className="col-12 col-sm-6">
                                <input type="email" className="form-control border-0" placeholder="Your Email" style={{ height: '55px' }} name="email"/>
                            </div>
                            <div className="col-12 col-sm-12">
                                <select className="form-select border-0" style={{ height: '55px' }} name="service"> 
                                    <option selected>Select a Service</option>
                                    <option value="1">Car Hire</option>
                                    <option value="2">School</option>
                                    <option value="3">Lodge</option>
                                </select>
                            </div>
                          
                            <div className="col-12">
                                <textarea className="form-control border-0" placeholder="Inquire" name="inquire"></textarea>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-secondary w-100 py-3" type="submit">Inquire Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


  );
}

export default BookSection;
