import React from 'react';
import { useQuery, gql } from '@apollo/client';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/owl.carousel.min.js';
const GET_WEBSITE_INFO = gql`
query WebsiteInfos {
  testimonials {
    name
    position
    image {
      url
    }
    testimony
  }
}


`;
function TestimonialSection() {


    const { loading, error, data } = useQuery(GET_WEBSITE_INFO);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { testimonials } = data;
  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="text-center">
                <h6 className="text-primary text-uppercase">Testimonial</h6>
                <h1 className="mb-5">Our Clients Say!</h1>
            </div>
            <OwlCarousel className='owl-carousel testimonial-carousel position-relative'  margin={15} >
            {/* <div className="owl-carousel testimonial-carousel position-relative"> */}
            {testimonials.map((testimony) => (
                <div className="testimonial-item text-center">
                    <img className="bg-light rounded-circle p-2 mx-auto mb-3" src={testimony.image.url} style={{ height: '80px', width: '80px' }} alt='' />
                    <h5 className="mb-0">{testimony.name}</h5>
                    <p>{testimony.position}</p>
                    <div className="testimonial-text bg-light text-center p-4">
                    <p className="mb-0">{testimony.testimony}</p>
                    </div>
                </div>
                ))}
            {/* </div> */}
            </OwlCarousel>
        </div>
    </div>


  );
}

export default TestimonialSection;
