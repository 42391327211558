import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useQuery, gql } from '@apollo/client';


const GET_WEBSITE_INFO = gql`
 query WebsiteInfos {
  websiteInfo(where: {id: "clucoyhl690im07mi40ms3tv8"}) {
    address
    email
    phoneNumbers
  }
}


`;
function Contact() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_5uaweuj', 'template_tp9v8ib', form.current, {
            publicKey: 'hbk2EPLpCZU_r9Jbn',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              alert("Thank you for your email. We will get back to you shortly.");
              form.current.reset();
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };

    const { loading, error, data } = useQuery(GET_WEBSITE_INFO);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { websiteInfo } = data;
    return (
<div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-primary text-uppercase">Contact Us</h6>
                <h1 className="mb-5">Contact For Any Query</h1>
            </div>
            <div className="row g-4">
                <div className="col-12">
                    <div className="row gy-4">
                        <div className="col-md-4">
                            <div className="bg-light d-flex flex-column justify-content-center p-4">
                                <h5 className="text-uppercase">Email</h5>
                                <p className="m-0"><i className="fa fa-envelope-open text-primary me-2"></i>{websiteInfo.email}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-light d-flex flex-column justify-content-center p-4">
                                <h5 className="text-uppercase">Phone Numbers</h5>
                                <p className="m-0"><i className="fa fa-phone-alt text-primary me-2"></i>{websiteInfo.phoneNumbers.join(', ')}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-light d-flex flex-column justify-content-center p-4">
                                <h5 className="text-uppercase">Address</h5>
                                <p className="m-0"><i className="fa fa-map-marker-alt text-primary me-2"></i>{websiteInfo.address}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                    <iframe className="position-relative rounded w-100 h-100"
                        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d122855.9195129198!2d35.0322688!3d-15.7908992!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2smw!4v1711749709635!5m2!1sen!2smw"
                        frameborder="0" style={{ minHeight: '350px', border: '0' }} allowfullscreen="" aria-hidden="false"
                        tabindex="0" title="map"></iframe>

                </div>
                <div className="col-md-6">
                    <div className="wow fadeInUp" data-wow-delay="0.2s">
                     
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="name" name="name" placeholder="Your Name"/>
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="email" className="form-control" id="email" placeholder="Your Email" name="email"/>
                                        <label for="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="subject" placeholder="Subject" name="subject" />
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a message here" id="message"  style={{ height: '350px'}} name="message"></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</div>

    );
  }
  export default Contact;