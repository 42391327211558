import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";

import Banner from "../../app/Banner";

function Service() {
  const { serviceSlug } = useParams();

  const GET_SERVICE_INFO = gql`
 query WebsiteInfos {
  service(where: {serviceSlug: "${serviceSlug}"}) {
    serviceDescription {
      html
    }
    serviceName
    serviceTagLine
    serviceImage {
      url
    }
    serviceGallery {
      url
    }
  }
}


`;

  const { loading, error, data } = useQuery(GET_SERVICE_INFO);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { service } = data;
  return (
    <>
      <Banner title={service.serviceName} isService={true} backgroundImage={service.serviceImage.url} />
      <div className="container">
        <div className="row">
          <div className="col-md-9">
          <h1>{service.serviceTagLine}</h1>
          <div dangerouslySetInnerHTML={{ __html: service.serviceDescription.html }} />
          </div>
          <div className="col-md-3">
          {service.serviceGallery.map((image, index) => (
            <>
                <img className="img-fluid w-100" key={index} src={image.url} alt={`Service ${index}`}/>
                <br/>
                <br/>
                </>
                ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default Service;
