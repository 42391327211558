
import { Link } from "react-router-dom";
import AboutSection from "../app/AboutSection";
import BookSection from "../app/BookSection";
import FactsSection from "../app/FactsSection";
import ServicesSection from "../app/ServicesSection";
import TeamSection from "../app/TeamSection";

function Home() {
    return (
        <div>
        <div className="container-fluid p-0 mb-5 ">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="w-100" src="img/cars.jpeg" alt="Prado"/>
                        <div className="carousel-caption d-flex align-items-center">
                            <div className="container">
                                <div className="row align-items-center justify-content-center justify-content-lg-start">
                                    <div className="col-10 col-lg-7 text-center text-lg-start">
                                        <h6 className="text-white text-uppercase mb-3 animated slideInDown"> Car Hire </h6>
                                        <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">Hire excellent cars from our fleet</h1>
                                        <Link to="/about" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></Link>
                                    </div>
                                    <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                                        <img className="img-fluid" src="img/prado.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src="img/lodge.jpg" alt="Logde"/>
                        <div className="carousel-caption d-flex align-items-center">
                            <div className="container">
                                <div className="row align-items-center justify-content-center justify-content-lg-start">
                                    <div className="col-10 col-lg-12 text-center text-lg-start">
                                        <h6 className="text-white text-uppercase mb-3 animated slideInDown">Lodge</h6>
                                        <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">Come and experience hospitality like no other at our lodge</h1>
                                        <Link to="/about" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></Link>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src="img/school.jpg" alt="Logde"/>
                        <div className="carousel-caption d-flex align-items-center">
                            <div className="container">
                                <div className="row align-items-center justify-content-center justify-content-lg-start">
                                    <div className="col-10 col-lg-12 text-center text-lg-start">
                                        <h6 className="text-white text-uppercase mb-3 animated slideInDown">School</h6>
                                        <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">We offer high quality education at our fine institutions</h1>
                                        <Link to="/about" className="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i className="fa fa-arrow-right ms-3"></i></Link>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        

        
    {/* <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="d-flex py-5 px-4">
                        <i className="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Quality Servicing</h5>
                            <p>Diam dolor diam ipsum sit amet diam et eos erat ipsum</p>
                            <a className="text-secondary border-bottom" href="">Read More</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="d-flex bg-light py-5 px-4">
                        <i className="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Expert Workers</h5>
                            <p>Diam dolor diam ipsum sit amet diam et eos erat ipsum</p>
                            <a className="text-secondary border-bottom" href="">Read More</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="d-flex py-5 px-4">
                        <i className="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Modern Equipment</h5>
                            <p>Diam dolor diam ipsum sit amet diam et eos erat ipsum</p>
                            <a className="text-secondary border-bottom" href="">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}


    <AboutSection/>
    <FactsSection/>
    <ServicesSection/>

    <BookSection/>

    <TeamSection/>

 


   


    </div>


    );
  }
  
  export default Home;
  