import React from 'react';
import { useQuery, gql } from '@apollo/client';


const GET_WEBSITE_INFO = gql`
 query WebsiteInfos {
  websiteInfo(where: {id: "clucoyhl690im07mi40ms3tv8"}) {
    numberOfCars
    numberOfSatisfiedClients
    dateStartedOperating
  }
}


`;
function FactsSection() {

    const { loading, error, data } = useQuery(GET_WEBSITE_INFO);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { websiteInfo } = data;
  return (
    <div className="container-fluid fact bg-dark my-5 py-5">
        <div className="container">
            <div className="row g-4">
                <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                    <i className="fa fa-check fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">{Math.floor((new Date() - new Date(websiteInfo.dateStartedOperating)) / (1000 * 60 * 60 * 24 * 365.25))}</h2>
                    <p className="text-white mb-0">Years Experience</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                    <i className="fa fa-users-cog fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">0</h2>
                    <p className="text-white mb-0">Services</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                    <i className="fa fa-users fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">{websiteInfo.numberOfSatisfiedClients}</h2>
                    <p className="text-white mb-0">Satisfied Clients</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                    <i className="fa fa-car fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">{websiteInfo.numberOfCars}</h2>
                    <p className="text-white mb-0">Cars</p>
                </div>
            </div>
        </div>
    </div>


  );
}

export default FactsSection;
