import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';


const GET_WEBSITE_INFO = gql`
query WebsiteInfos {
  services {
    serviceName
    serviceSlug
    serviceTagLine
    serviceIcon
    serviceDescription {
      html
    }
    serviceImage {
      url
    }
  }
}


`;
function ServicesSection() {

    const { loading, error, data } = useQuery(GET_WEBSITE_INFO);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { services } = data;
  return (
    <div className="container-xxl service py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-primary text-uppercase">Our Services</h6>
                <h1 className="mb-5">Explore Our Services</h1>
            </div>
            <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                <div className="col-lg-4">
                    <div className="nav w-100 nav-pills me-4">
                    {services.map((service) => (
                        <button className={`nav-link w-100 d-flex align-items-center text-start p-4 mb-4 ${service.serviceSlug === 'car-hire' ? 'show active' : ''} `} data-bs-toggle="pill" data-bs-target={`#${service.serviceSlug}`} type="button">
                            <i className={`fa fa-${service.serviceIcon} fa-2x me-3`}></i>
                            <h4 className="m-0">{service.serviceName}</h4>
                        </button>
                        ))}
                       
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="tab-content w-100">
                    {services.map((service) => (
                        <div className={`tab-pane fade ${service.serviceSlug === 'car-hire' ? 'show active' : ''}`}  id={`${service.serviceSlug}`}>
                            <div className="row g-4">
                                <div className="col-md-6" style={{ minHeight: '350px' }}>
                                    <div className="position-relative h-100">
                                        <img className="position-absolute img-fluid w-100 h-100" src={service.serviceImage.url}
                                            style={{ objectFit: 'cover' }} alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="mb-3">{service.serviceTagLine}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: service.serviceDescription.html.substring(0, 350) }} />
                                    
                                    <Link to={`/services/${service.serviceSlug}`} className="btn btn-primary py-3 px-5 mt-3">Read More<i className="fa fa-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
  ))}

                        
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>


  );
}

export default ServicesSection;
