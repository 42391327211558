import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { NavLink, useLocation } from "react-router-dom";


const GET_WEBSITE_INFO = gql`
 query WebsiteInfos {
  websiteInfo(where: {id: "clucoyhl690im07mi40ms3tv8"}) {
 
    dateStartedOperating
  }
}


`;
function AboutSection() {

    const { loading, error, data } = useQuery(GET_WEBSITE_INFO);
    const location = useLocation();

    // Check if the current route is "/about"
    const isAboutPage = location.pathname === '/about';

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const { websiteInfo } = data;

    
  return (
    <div className="container-xxl py-5">
    <div className="container">
        <div className="row g-5">
            <div className="col-lg-6 pt-4" style={{ minHeight: '400px' }}>
                <div className="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                    <img className="position-absolute img-fluid w-100 h-100" src="img/car3.jpg" style={{ objectFit: 'cover' }} alt="about"/>
                    <div className="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: 'rgba(0, 0, 0, 0.08)' }}>
                        <h1 className="display-4 text-white mb-0">{Math.floor((new Date() - new Date(websiteInfo.dateStartedOperating)) / (1000 * 60 * 60 * 24 * 365.25))} <span className="fs-4">Years</span></h1>
                        <h4 className="text-white">Experience</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <h6 className="text-primary text-uppercase">    About Us</h6>
                <h1 className="mb-4"><span className="text-primary">Penda Investments</span> </h1>
                <p className="mb-4">Is a Malawian owned company whose aim is to provide reliable and excellent services in car hire, logding, and education in Malawi and beyond. The company was established in 2017 and currently has operations running in Blantyre with strong networks with various companies across all regions in Malawi and beyond</p>
                <div className="row g-4 mb-3 pb-3">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                        <div className="d-flex">
                            <div className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{ width: '45px', height: '45px' }}>
                                <span className="fw-bold text-secondary">01</span>
                            </div>
                            <div className="ps-3">
                                <h6>Car Hire </h6>
                                <span>Penda Investments boasts of car-hire with a fleet of vehicles.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                        <div className="d-flex">
                            <div className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{ width: '45px', height: '45px' }}>
                                <span className="fw-bold text-secondary">02</span>
                            </div>
                            <div className="ps-3">
                                <h6>School</h6>
                                <span>Penda Investments as Nursery, Primary and Secondary Schools</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                        <div className="d-flex">
                            <div className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1" style={{ width: '45px', height: '45px' }}>
                                <span className="fw-bold text-secondary">03</span>
                            </div>
                            <div className="ps-3">
                                <h6>Lodge</h6>
                                <span>Penda Investments has a lodge with a capacity of five self-contained and air-conditioned rooms and conference Hall capable of hosting 40 guests at a time</span>
                            </div>
                        </div>
                    </div>
                </div>
                {!isAboutPage && (
        <NavLink to="/about" className="btn btn-primary py-3 px-5">
          Read More<i className="fa fa-arrow-right ms-3"></i>
        </NavLink>
      )}
                
            </div>
        </div>
    </div>
</div>

  );
}

export default AboutSection;
