import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Outlet, NavLink, Link } from "react-router-dom";

const GET_WEBSITE_INFO = gql`
 query WebsiteInfos {
  websiteInfo(where: {id: "clucoyhl690im07mi40ms3tv8"}) {
    aboutText
    address
    createdAt
    dateStartedOperating
    email
    facebookLink
    id
    linkedInLink
    mondayFridayOpeningHours
    numberOfCars
    numberOfSatisfiedClients
    phoneNumbers
    publishedAt
    twitterLink
    youtubeLink
    saturdaySundayOpeningHours
  }
  services {
    serviceName
    serviceSlug
    serviceTagLine
    serviceIcon
  }
}


`;
const Layout = () => {
  const { loading, error, data } = useQuery(GET_WEBSITE_INFO);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { websiteInfo, services } = data;

  return (
<div className="App">
     
     
  
  
      <div className="container-fluid bg-light p-0">
          <div className="row gx-0 d-none d-lg-flex">
              <div className="col-lg-7 px-5 text-start">
                  <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                      <small className="fa fa-map-marker-alt text-primary me-2"></small>
                      <small>{websiteInfo.address}</small>
                  </div>
                  <div className="h-100 d-inline-flex align-items-center py-3">
                      <small className="far fa-clock text-primary me-2"></small>
                      <small>Mon - Fri : {websiteInfo.mondayFridayOpeningHours}</small>
                  </div>
              </div>
              <div className="col-lg-5 px-5 text-end">
                  <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                      <small className="fa fa-phone-alt text-primary me-2"></small>
                      <small>{websiteInfo.phoneNumbers[0]}</small>
                  </div>
                  <div className="h-100 d-inline-flex align-items-center">
                      <a className="btn btn-sm-square bg-white text-primary me-1" href={websiteInfo.facebookLink} target='_blank' rel='noreferrer'><i className="fab fa-facebook-f"></i></a>
                      <a className="btn btn-sm-square bg-white text-primary me-1" href={websiteInfo.twitterLink} target='_blank' rel='noreferrer'><i className="fab fa-twitter"></i></a>
                      <a className="btn btn-sm-square bg-white text-primary me-1" href={websiteInfo.linkedInLink} target='_blank' rel='noreferrer'><i className="fab fa-linkedin-in"></i></a>
                      <a className="btn btn-sm-square bg-white text-primary me-0" href={websiteInfo.youtubeLink} target='_blank' rel='noreferrer'><i className="fab fa-youtube"></i></a>
                  </div>
              </div>
          </div>
      </div>
  
  
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
          <NavLink to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
          <img className="img-fluid w-100 h-100" src="/img/logo.png" alt=""/>
          </NavLink>
          <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-4 p-lg-0">
                  <NavLink to="/" className="nav-item nav-link">Home</NavLink>
                  <NavLink to="/about" className="nav-item nav-link">About</NavLink>
                  <NavLink to="/services" className="nav-item nav-link">Services</NavLink>

                  <NavLink to="/contact" className="nav-item nav-link">Contact</NavLink>
              </div>
              <Link to="/contact" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Get A Quote<i className="fa fa-arrow-right ms-3"></i></Link>
          </div>
      </nav>


      <Outlet />


    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>{websiteInfo.address}</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{websiteInfo.phoneNumbers[1]}</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>{websiteInfo.email}</p>
                   
                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href={websiteInfo.twitterLink} target='_blank' rel='noreferrer'><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href={websiteInfo.facebookLink} target='_blank' rel='noreferrer'><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href={websiteInfo.youtubeLink} target='_blank' rel='noreferrer'><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-outline-light btn-social" href={websiteInfo.linkedInLink} target='_blank' rel='noreferrer'><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Opening Hours</h4>
                    <h6 className="text-light">Monday - Friday:</h6>
                    <p className="mb-4">{websiteInfo.mondayFridayOpeningHours}</p>
                    <h6 className="text-light">Saturday - Sunday:</h6>
                    <p className="mb-0">{websiteInfo.saturdaySundayOpeningHours}</p>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Services</h4>
                    {services.map((service) => (
                    <Link to={`/services/${service.serviceSlug}`} className="btn btn-link" >{service.serviceName}</Link>
                    ))}

                </div>
           
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="/">Penda Investments</a>, All Right Reserved.

                       
                        Designed By <a className="border-bottom" href="/">Nyemba</a>
                    </div>

                </div>
            </div>
        </div>
    </div>


    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
      </div>
        )
    };
    
    export default Layout;