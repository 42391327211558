

import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Layout from "./components/layout/Layout";
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient'; 
import Services from "./components/pages/Services/Services";
import Service from "./components/pages/Services/Service";

export default function App() {
  return (
    <ApolloProvider client={client}>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="/services/:serviceSlug" element={<Service />} />
        </Route>
      </Routes>
    </HashRouter>
    </ApolloProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);